<script setup lang="ts">
import { ref } from "vue";
import localStorageKeys from "@/constants/localStorageKeys";

const isPro = ref(localStorage.getItem(localStorageKeys.isPro) === "true");

const handleSwitchChange = () => {
  localStorage.setItem(localStorageKeys.isPro, isPro.value.toString());
};
</script>

<template>
  <div class="flex flex-col h-full justify-center items-center">
    <v-switch
      v-model="isPro"
      label="Enable for pro features"
      color="blue"
      hide-details
      inset
      @change="handleSwitchChange"
    />
  </div>
</template>
