<script setup lang="ts">
import { defineProps, onMounted, ref } from 'vue';

const props = defineProps<{
  value?: string;
  placeholder?: string;
  inputRef?: (ref: HTMLInputElement | undefined) => void;
  onInputChange?: (value: string) => void;
}>();

let inputRef = ref<HTMLInputElement>();

const handleChange = (event: Event) => {
  props.onInputChange?.((event.target as HTMLInputElement).value);
};

onMounted(() => {
  props.inputRef?.(inputRef.value);
});
</script>

<template>
  <div
    class="flex justify-between w-full !bg-black4 px-4 py-[10px] text-base font-normal rounded-lg border border-black3 hover:!border-white3"
  >
    <input
      type="text"
      ref="inputRef"
      @input="handleChange"
      :value="props.value"
      :placeholder="props.placeholder"
      class="border-none focus:outline-none placeholder:text-black3 text-white w-full"
    />
  </div>
</template>
