<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <circle cx="10" cy="10" r="10" fill="#393B47" />
    <path
      d="M5 10.5L8.06122 13L15 6"
      stroke="#21222C"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "UnCheckedIcon",
};
</script>
