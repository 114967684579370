<script setup lang="ts">
import { defineProps } from 'vue';
interface MainSearchBarProps {
  onSearch: () => void;
  onInputChange: (value: string) => void;
}
const props = defineProps<MainSearchBarProps>();

function handleSearch() {
  props.onSearch?.();
}
function handleInputChange(event: any) {
  props.onInputChange(event.target.value);
}
</script>

<template>
  <form @submit.prevent class="flex w-fit text-xl font-normal shadow-lg">
    <input
      @input="handleInputChange"
      type="text"
      placeholder="Enter Product Name or Code"
      class="w-[543px] bg-black4 p-5 rounded-tl-lg rounded-bl-lg focus:outline-none placeholder:text-black3 !text-white"
    />
    <button
      @click="handleSearch"
      type="submit"
      class="flex items-center justify-center rounded-tr-lg rounded-br-lg !bg-green w-[154px] text-black"
    >
      Search
    </button>
  </form>
</template>
