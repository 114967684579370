<template>
  <div
    @click="onClick"
    :class="isSelected ? 'bg-white2' : 'bg-black'"
    class="flex flex-col items-start rounded-2xl p-4 relative h-[160px] w-[274px] cursor-pointer"
  >
    <div class="flex flex-col space-y-4">
      <p :class="isSelected ? 'text-black' : '!text-white3'" class="p">
        {{ title }}
      </p>
      <p2
        :class="isSelected ? 'text-black3' : 'text-white3'"
        class="p2 text-black3"
        >{{ description }}</p2
      >
    </div>
    <div class="absolute top-4 right-4">
      <CheckedIcon v-if="isSelected" />
      <UnCheckedIcon v-else />
    </div>
  </div>
</template>

<script lang="ts">
import CheckedIcon from "@/components/atoms/icons/CheckedIcon.vue";
import UnCheckedIcon from "@/components/atoms/icons/UnCheckedIcon.vue";
import { PropType } from "vue";

export default {
  name: "SearchOptionChoiceCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      req: true,
    },
    onClick: { type: Function as PropType<any> },
  },
  components: { CheckedIcon, UnCheckedIcon },
};
</script>
