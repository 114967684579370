import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import './assets/global.css';
import { createPinia } from 'pinia';
import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { OhVueIcon, addIcons } from 'oh-vue-icons';
import { BiArrowReturnRight, MdKeyboardarrowdownRound, FaQuestionCircle } from 'oh-vue-icons/icons';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

addIcons(BiArrowReturnRight, MdKeyboardarrowdownRound, FaQuestionCircle);

const vuetify = createVuetify({
  components,
  directives,
});
const pinia = createPinia();

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
};

const app = createApp(App);

app.config.errorHandler = (err, vm, info) => {
  // Handles runtime error

  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.

  console.log('Error occur:', err);
};

app.use(router);
app.use(pinia);
app.use(VueQueryPlugin, vueQueryPluginOptions);
app.use(vuetify);
app.use(ToastPlugin);
app.component('v-icon', OhVueIcon);
app.mount('#app');
