<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-col items-center h1">
      <p>HS Code and Dual Use Code</p>
      <p class="text-blue">Lookup</p>
    </div>
    <MainSearchBar
      class="mt-12"
      :searchQuery="searchState.query"
      :onSearch="handleSearch"
      :onInputChange="(val) => (searchState.query = val)"
    />

    <div class="flex w-full space-x-8 items-center h-16 justify-end h3 !text-blue mt-10">
      <AppTextField
        v-if="openHsCodeSearch"
        class="w-[500px] h-full shadow-lg !bg-black4 p-5 rounded-tl-lg rounded-bl-lg focus:outline-none placeholder:text-black3 !text-white"
        placeholder="Enter hs code"
        :value="searchState.hsCode"
        :onInputChange="(val) => (searchState.hsCode = val)"
      />
      <button @click="toggleHsCodeSearch" class="flex items-center justify-end pr-2">
        <p>HsCode</p>
        <v-icon
          name="md-keyboardarrowdown-round"
          scale="1.5"
          class="focus:outline-none transform transition duration-300"
          :class="{ 'rotate-180': openHsCodeSearch }"
        ></v-icon>
      </button>
    </div>

    <div class="flex space-x-4 pt-[88px]">
      <SearchOptionChoiceCard
        title="Default Search"
        description="AI will automatically give you the final HS code or Dual Code"
        :isSelected="searchState.option === 'automatic'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import MainSearchBar from '@/components/molecules/MainSearchBar.vue';
import SearchOptionChoiceCard from '@/components/molecules/SearchOptionChoiceCard.vue';
import { AppDocumentType } from '@/@types/document';
import { SearchOptionType } from '@/@types/search';
import AppTextField from '../atoms/inputs/AppTextField.vue';
import { useRouter } from 'vue-router';
import { ref, reactive } from 'vue';

const router = useRouter();

const searchState = reactive<{
  query: string;
  hsCode: string;
  option: SearchOptionType;
  documentType: AppDocumentType;
}>({
  query: '',
  option: 'automatic',
  hsCode: '',
  documentType: 'hs_code',
});

const openHsCodeSearch = ref(false);

function handleSearch() {
  if (!searchState.query) return;
  router.push(`/search?query=${searchState.query}&hs_code=${searchState.hsCode}`);
}

function toggleHsCodeSearch() {
  openHsCodeSearch.value = !openHsCodeSearch.value;
}
</script>
