<script setup lang="ts">
import { useRoute } from 'vue-router';
import NavBar from './components/molecules/NavBar.vue';
const route = useRoute();
</script>

<template>
  <div class="flex flex-col min-w-screen min-h-screen bg-primaryBg text-white font-inter">
    <NavBar :showSearch="route.name === 'search'" class="fixed top-0 z-50" />
    <router-view class="!min-h-content w-full bg-primaryBg !mt-navbar" />
  </div>
</template>
