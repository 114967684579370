import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SearchView from "../views/search/SearchView.vue";
import ProView from "../views/ProView.vue";

function lazyLoad(view: string) {
  return () => import(`../views/search/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/search",
    name: "search",
    component: lazyLoad("SearchView"),
  },
  {
    path: "/pro",
    name: "pro",
    component: ProView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
