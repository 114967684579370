<template>
  <div
    @click="() => $router.push('/')"
    class="flex flex-row items-end space-x-2 text-2xl font-semibold cursor-pointer"
  >
    <img src="@/assets/icons/logo.svg" />
    <p>regtech</p>
  </div>
</template>

<script lang="ts">
export default {
  name: "AppBrand",
};
</script>
