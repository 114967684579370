import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-4" }
const _hoisted_2 = { class: "absolute top-4 right-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p2 = _resolveComponent("p2")!
  const _component_CheckedIcon = _resolveComponent("CheckedIcon")!
  const _component_UnCheckedIcon = _resolveComponent("UnCheckedIcon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($props.onClick && $props.onClick(...args))),
    class: _normalizeClass([$props.isSelected ? 'bg-white2' : 'bg-black', "flex flex-col items-start rounded-2xl p-4 relative h-[160px] w-[274px] cursor-pointer"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", {
        class: _normalizeClass([$props.isSelected ? 'text-black' : '!text-white3', "p"])
      }, _toDisplayString($props.title), 3),
      _createVNode(_component_p2, {
        class: _normalizeClass([$props.isSelected ? 'text-black3' : 'text-white3', "p2 text-black3"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.description), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      ($props.isSelected)
        ? (_openBlock(), _createBlock(_component_CheckedIcon, { key: 0 }))
        : (_openBlock(), _createBlock(_component_UnCheckedIcon, { key: 1 }))
    ])
  ], 2))
}